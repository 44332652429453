import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import KeywordSelectionPage from "./components/KeywordSelectionPage";
import ResultPage from "./components/ResultPage";
import EmailVerification from "./components/EmailVerification";
import VerifyToken from "./components/VerifyToken";
import FirmURL from "./components/FirmURL";
import CheckoutPage from "./components/Checkout";
import UserRegistration from "./components/UserRegistartion";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Outlet />}>
          <Route path="" element={<ProtectedRoute />}>
            <Route index path="" element={<FirmURL />} />
            <Route path="keywords" element={<KeywordSelectionPage />} />
            <Route path="results" element={<ResultPage />} />
            <Route path="checkout" element={<CheckoutPage />} />
            <Route path="register" element={<UserRegistration />} />
          </Route>
          <Route path="email-verification" element={<EmailVerification />} />
          <Route path="verify-token" element={<VerifyToken />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
