import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const URLForm: React.FC = () => {
  const [url, setUrl] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Call backend API to scrape website and store in database
    // const response = await fetch("/api/scrape", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ url }),
    // });

    // const data = await response.json();
    const { key } = { key: "1234" };

    // Redirect to Keyword Selection Page with the key
    navigate("keywords", { state: { key } });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <input
        type="text"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        placeholder="Enter website URL"
        required
        className="w-full p-3 border rounded"
      />
      <button
        type="submit"
        className="w-full bg-blue-500 text-white p-3 rounded"
      >
        Submit
      </button>
    </form>
  );
};

export default URLForm;
