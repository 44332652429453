import React, { useState } from "react";
import EmailForm from "./EmailForm";
const EmailVerification: React.FC = () => {
  const [message, setMessage] = useState("");

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        {message === "" ? (
          <div>
            <h1 className="text-2xl font-bold mb-6 text-center">
              Enter your email address
            </h1>
            <EmailForm setMessage={setMessage} />
          </div>
        ) : (
          <h1 className="text-2xl font-bold mb-6 text-center">{message}</h1>
        )}
      </div>
    </div>
  );
};

export default EmailVerification;
