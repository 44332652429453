import { useNavigate } from "react-router-dom";

export interface Plan {
  name: string;
  price: string; // The price, for example, "$19.99"
  features: string[]; // List of features for the plan
}

const plans = [
  {
    name: "Silver",
    price: "$19.99",
    features: ["Basic Features", "Up to 5 users", "Standard Support"],
  },
  {
    name: "Gold",
    price: "$49.99",
    features: ["Advanced Features", "Up to 50 users", "Priority Support"],
  },
  {
    name: "Platinum",
    price: "$99.99",
    features: ["All Features", "Unlimited users", "24/7 Support"],
  },
];

const Plans = () => {
  const navigate = useNavigate();
  const handlePlanSelection = (plan: any) => {
    navigate("/checkout", { state: { plan } });
  };

  return (
    <div className="mt-10 mb-10 w-full">
      <h2 className="text-2xl font-bold text-center mb-4">Choose Your Plan</h2>
      <div className="flex flex-wrap justify-center gap-6">
        {plans.map((plan) => (
          <div
            key={plan.name}
            className="bg-white shadow-lg rounded-lg p-6 w-64 text-center"
          >
            <h3 className="text-xl font-bold mb-4">{plan.name}</h3>
            <p className="text-3xl font-semibold mb-4">{plan.price}</p>
            <ul className="text-left mb-6">
              {plan.features.map((feature, index) => (
                <li key={index} className="text-gray-600 mb-2">
                  {feature}
                </li>
              ))}
            </ul>
            <button
              onClick={() => handlePlanSelection(plan)}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition"
            >
              Buy {plan.name}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Plans;
