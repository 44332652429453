import * as React from "react";
import {
  createColumnHelper,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import SwapVertIcon from "@mui/icons-material/SwapVert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import IconButton from "@mui/material/IconButton";
import dayjs, { Dayjs } from "dayjs";

import "../styles/reactTableListing.css";
import Pagination from "./Pagination";
import { useState } from "react";
import CircularProgressWithLabel from "./CircularProgress";
import { Collapse } from "@mui/material";

type Opp = {
  noticeUid: string;
  agency: string;
  contact: string;
  date: string;
  description: string;
  link: string;
  naics: string;
  nsn: string | null;
  popzip: string;
  responseDate: string;
  setAsideCode: string;
  source: string;
  status: string;
  title: string;
  lastScore: number;
};

type ReactListingTableProps = {
  opps: Opp[];
};

const columnHelper = createColumnHelper<Opp>();

const columns = [
  columnHelper.accessor("title", {
    header: "Title",
    cell: ({ row, getValue }) => (
      <div className="flex items-center">
        <IconButton
          onClick={() => row.toggleExpanded()}
          className="mr-2 text-blue-500 hover:text-blue-700"
        >
          {row.getIsExpanded() ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        <span className="text-lg font-semibold">{getValue()}</span>
      </div>
    ),
    size: 300,
  }),
  columnHelper.accessor("agency", {
    header: "Agency",
    cell: (info) => <span className="text-gray-700">{info.getValue()}</span>,
  }),
  columnHelper.accessor("naics", {
    header: "Description",
    cell: (info) => (
      <span className="text-gray-700">NAICS-{info.getValue()}</span>
    ),
  }),
  columnHelper.accessor("responseDate", {
    header: "Date",
    cell: (info) => (
      <span className="text-gray-700">
        {dayjs(info.getValue()).format("MM/DD/YYYY")}
      </span>
    ),
  }),
  columnHelper.accessor("setAsideCode", {
    header: "Setaside",
    cell: (info) => <span className="text-gray-700">{info.getValue()}</span>,
  }),
  columnHelper.accessor("status", {
    header: "Status",
    cell: (info) => <span className="text-gray-700">{info.getValue()}</span>,
  }),
  columnHelper.accessor("link", {
    header: "Link",
    cell: (info) => (
      <a
        href={info.getValue()}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 hover:underline"
      >
        View Link
      </a>
    ),
  }),
  columnHelper.accessor("lastScore", {
    id: "score",
    header: "Score",
    cell: (info) => (
      <div className="flex items-center">
        <CircularProgressWithLabel value={info.getValue()} />
      </div>
    ),
  }),
];

const ReactListingTable: React.FC<ReactListingTableProps> = ({ opps }) => {
  const data = React.useState(() => [...opps])[0];

  const [pagination, setPagination] = useState({
    pageSize: 5,
    pageIndex: 0,
  });

  const table = useReactTable({
    data,
    columns,
    state: {
      pagination,
    },
    defaultColumn: {
      maxSize: 500, //enforced during column resizing
    },
    getCoreRowModel: getCoreRowModel(),
    columnResizeMode: "onChange",
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: (newState) => {
      setPagination(newState);
    },
    getExpandedRowModel: getExpandedRowModel(),
  });

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <table className="min-w-full border-collapse border-[1px] border-[#dfe1e5] rounded-lg overflow-hidden">
        <thead className="bg-blue-500">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className="p-4 text-center text-xl font-extrabold text-white"
                  style={{ width: header.getSize() }}
                >
                  <div className="flex items-center">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {header.column.getCanSort() && (
                      <SwapVertIcon
                        fontSize="medium"
                        className="cursor-pointer ml-1 text-gray-500 hover:text-gray-700"
                        onClick={header.column.getToggleSortingHandler()}
                      />
                    )}
                    {
                      {
                        asc: "↑",
                        desc: "↓",
                      }[(header.column.getIsSorted() as "asc" | "desc") || ""]
                    }
                    <div
                      onDoubleClick={() => header.column.resetSize()}
                      onMouseDown={header.getResizeHandler()}
                      onTouchStart={header.getResizeHandler()}
                      className={`resizer ml-2 ${
                        table.options.columnResizeDirection
                      } ${header.column.getIsResizing() ? "isResizing" : ""}`}
                    />
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <React.Fragment key={row.id}>
              <tr className="hover:bg-gray-50">
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="p-4 text-lg text-gray-700">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
              {row.getIsExpanded() && (
                <tr className="bg-gray-50">
                  <td colSpan={columns.length} className="p--">
                    <Collapse
                      in={row.getIsExpanded()}
                      timeout={1000} // Set to 0 to control the timing entirely via CSS
                      unmountOnExit
                    >
                      <div className="p-4">
                        <p className="text-md text-gray-600">
                          {row.original.description}
                        </p>
                      </div>
                    </Collapse>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <div className="mt-4 border-t-[1px]">
        <Pagination table={table} />
      </div>
    </div>
  );
};

export default ReactListingTable;
