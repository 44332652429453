import React from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface PaginationProps {
  getState: () => { pagination: { pageIndex: number } };
  setPageIndex: (index: number) => void;
  getPageCount: () => number;
  getCanPreviousPage: () => boolean;
  getCanNextPage: () => boolean;
  previousPage: () => void;
  nextPage: () => void;
}

const Pagination: React.FC<{ table: PaginationProps }> = ({ table }) => {
  const { getState, setPageIndex, getPageCount } = table;

  const { pageIndex } = getState().pagination;
  const pageCount = getPageCount();

  const handlePreviousPage = () => {
    table.previousPage();
  };

  const handleNextPage = () => {
    table.nextPage();
  };

  return (
    <div className="flex justify-center items-center p-3">
      <IconButton
        onClick={handlePreviousPage}
        disabled={!table.getCanPreviousPage()}
      >
        <ArrowBackIcon />
      </IconButton>
      <span>Page {`${pageIndex + 1} / ${pageCount}`}</span>
      <IconButton onClick={handleNextPage} disabled={!table.getCanNextPage()}>
        <ArrowForwardIcon />
      </IconButton>
    </div>
  );
};

export default Pagination;
