import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ListingTable from "./ListingTable";
import ReactListingTable from "./UpdatedReactListing";
import Plans from "./Plans";
import PayPalCheckout from "./PaypalCheckout";

const ResultPage: React.FC = () => {
  const location = useLocation();
  const { selectedKeywords } = location.state || { selectedKeywords: [] };
  const [results, setResults] = useState<any>(null);

  useEffect(() => {
    //Fetch results based on selected keywords
    const fetchResults = async () => {
      const token = localStorage.getItem("token");
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_API_URL
          }/demo/opportunities?keywords=${selectedKeywords.join(",")}`,
          axiosConfig
        );
        console.log(response.data.opps);
        setResults(response.data.opps);
      } catch (error) {
        console.error("Error fetching results:", error);
      }
    };

    if (selectedKeywords.length > 0) {
      fetchResults();
    }
  }, [selectedKeywords]);
  return (
    <div className="min-h-screen flex flex-col items-center justify-center">
      <h1 className="text-2xl font-bold mb-6 text-center">Results</h1>
      {selectedKeywords.length === 0 ? (
        <h2 className="text-xl text-center text-red-500">
          No keywords were selected.
        </h2>
      ) : results ? (
        <div>
          <ReactListingTable opps={results} />
          <Plans />
        </div>
      ) : (
        // <h2 className="text-xl text-center">Loading...</h2>
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
        </div>
      )}
    </div>
  );
};

export default ResultPage;
