import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

interface EmailFormProps {
  setMessage: (message: string) => void;
}

const EmailForm: React.FC<EmailFormProps> = ({ setMessage }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Call backend API to scrape website and store in database
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/request-demo-access`,
      { email }
    );

    setMessage(response.data.message);
  };
  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <input
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email address"
        required
        className="w-full p-3 border rounded"
      />
      <button
        type="submit"
        className="w-full bg-blue-500 text-white p-3 rounded"
      >
        Submit
      </button>
    </form>
  );
};

export default EmailForm;
