import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PayPalCheckout from "./PaypalCheckout"; // Import your PayPalCheckout component
import { Plan } from "./Plans";

// Ensure that the plan object adheres to the Plan interface
const CheckoutPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { plan } = location.state as { plan: Plan }; // Type assertion

  const handlePostCheckout = (trxDetails: any) => {
    //Call backend api here to store transaction details
    console.log("TRansaction Details in checkout component ", trxDetails);
    navigate("register");
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="mt-10 mb-1 md:w-1/3 w-auto">
        <h2 className="text-2xl font-bold text-center mb-4">Checkout</h2>

        {/* Display selected plan details */}
        <div className="bg-white shadow-lg rounded-lg p-6 text-center mx-auto">
          <h3 className="text-xl font-bold mb-4">{plan.name}</h3>
          <p className="text-3xl font-semibold mb-4">{plan.price}</p>
          <ul className="text-left mb-6">
            {plan.features.map((feature: string, index: number) => (
              <li key={index} className="text-gray-600 mb-2">
                {feature}
              </li>
            ))}
          </ul>
        </div>

        {/* Render PayPalCheckout Component and pass the selected plan */}
        <div className="mt-10">
          <PayPalCheckout plan={plan} onPostcheckout={handlePostCheckout} />{" "}
          {/* Pass the plan as a prop */}
        </div>
      </div>
    </div>
  );
};

export default CheckoutPage;
