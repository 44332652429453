import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import KeywordList from "./KeywordList";
import axios from "axios";

const KeywordSelectionPage: React.FC = () => {
  const [keywords, setKeywords] = useState<string[]>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { key } = location.state;

  useEffect(() => {
    // Fetch keywords from backend using the key
    const fetchKeywords = async () => {
      const token = localStorage.getItem("token");
      const axiosConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/demo/extract_meaningful_words`,
          {
            text: "With clients including Facebook, the Chicago Transit Authority, and the University of Virginia, Avicado provides custom software and data services to help manage construction projects. The company was also an Inc. Best Workplaces honoree in 2021, thanks to perks like an unlimited time-off policy, flexible work schedules, professional development opportunities, and an annual company-wide incentive trip",
          },
          axiosConfig
        );
        console.log(response.data.keywords);
        setKeywords(response.data.keywords);
      } catch (error) {
        console.error("Error fetching keywords:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchKeywords();
  }, [key]);

  const handleKeywordChange = (keyword: string) => {
    setSelectedKeywords((prevSelected) =>
      prevSelected.includes(keyword)
        ? prevSelected.filter((k) => k !== keyword)
        : [...prevSelected, keyword]
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Redirect to Result Page
    navigate("/results", { state: { selectedKeywords } });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      {loading ? (
        <div className="flex justify-center items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="bg-white p-8 rounded shadow-md w-full max-w-md"
        >
          <h1 className="text-2xl font-bold mb-6 text-center">
            Select Keywords
          </h1>
          <div className="mb-4">
            <KeywordList
              keywords={keywords}
              onKeywordChange={handleKeywordChange}
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-3 rounded"
          >
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default KeywordSelectionPage;
