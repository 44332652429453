import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

const VerifyToken = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    if (token) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/auth/verify-demo-token`, {
          params: { token },
        })
        .then((response) => {
          const data = response.data;
          if (data.token) {
            localStorage.setItem("token", data.token);
            navigate("/"); // Redirect to your main teaser page or another route
          } else {
            alert("Token verification failed.");
          }
        })
        .catch((err) => {
          console.error("Error verifying token:", err);
          alert("An error occurred. Please try again.");
        });
    } else {
      alert("No token found in URL.");
      // Redirect if no token is found
    }
  }, [location, navigate]);

  return <div>Verifying your access...</div>;
};

export default VerifyToken;
