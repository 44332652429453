import React from "react";

interface KeywordListProps {
  keywords: string[];
  onKeywordChange: (keyword: string) => void;
}

const KeywordList: React.FC<KeywordListProps> = ({
  keywords,
  onKeywordChange,
}) => {
  return (
    <div className="space-y-2">
      {keywords.map((keyword) => (
        <div key={keyword} className="flex items-center">
          <input
            type="checkbox"
            id={keyword}
            onChange={() => onKeywordChange(keyword)}
            className="mr-2"
          />
          <label htmlFor={keyword} className="text-lg">
            {keyword}
          </label>
        </div>
      ))}
    </div>
  );
};

export default KeywordList;
