import React, { useEffect } from "react";
import URLForm from "./URLForm";
import { Outlet, useNavigate } from "react-router-dom";
import { validate } from "../utils/auth";

const ProtectedRoute: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    validate().then((response) => {
      if (!response) {
        navigate("/email-verification");
      }
    });
  }, [navigate]);

  return <Outlet />;
};

export default ProtectedRoute;
